<template>
  <div>
    <v-dialog
      persistent
      :width="dialogWidth"
      class="responsive-dialog"
      v-model="videoPlayerDialog"
    >
      <div class="dialog-container">
        <Player
          :autoplay="true"
          hideOnMouseLeave
          :activeDuration="1500"
          :style="{
            '--vm-slider-value-color': '#A6CC39',
            '--vm-time-color': '#FFFFFF',
          }"
          playsinline
          ref="player"
          :duration="duration"
          :currentTime="currentTime"
          :buffering="buffering"
          @vmFullscreenChange="onFullscreenChange"
          @vmPlayingChange="onPlayingChange"
          @vmCurrentTimeChange="onTimeUpdate"
          @vmPlaybackEnded="onPlaybackEnded"
          @vmPlaybackStarted="onStartNewPlayback"
          @vmSeeked="onSeekedVideo(true)"
          @vmDurationChange="onDurationChange"
          @vmCurrentSrcChange="onCurrentSrcChange"
          @vmPlaybackReady="onReadytoPlay"
          @vmBufferingChange="onVideoBuffering"
        >
          <div class="back-icon-container">
            <v-btn
              dark
              id="ButtonAnimationBack"
              style="cursor: pointer; min-width: 29px"
              @click.stop="showExitVideoPlayerDialog()"
            >
              <v-img
                src="@/assets/images/player-back-icon.svg"
                width="29"
                height="29"
                style="cursor: pointer"
                class="mx-auto"
              ></v-img>
            </v-btn>
          </div>

          <Video>
            <source :data-src="videoFile" type="video/mp4" />
          </Video>
          <Ui>
            <Scrim />
            <div
              v-if="loadVideo"
              style="
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-progress-circular
                v-if="loadVideo"
                indeterminate
                :size="70"
                class="loading-circle"
                color="#A6CC39"
              ></v-progress-circular>
            </div>
            <Controls justify="center">
              <div class="controls">
                <ControlGroup class="controls-padding">
                  <!-- hideTooltip -->
                  <div class="progress-bar" @click="seekVideo">
                    <div class="progress-bar__background"></div>
                    <div
                      class="progress-bar__fill"
                      :style="{ width: progressPercentage }"
                    ></div>
                    <div
                      class="progress-bar__handle"
                      :style="{ left: progressPercentage }"
                    ></div>
                  </div>
                </ControlGroup>

                <ControlGroup
                  class="mt-1"
                  style="padding-left: 19px; padding-right: 19px"
                >
                  <CurrentTime alwaysShowHours />
                  <ControlSpacer />
                  <EndTime alwaysShowHours />
                </ControlGroup>

                <ControlGroup
                  class="mt-0"
                  style="
                    padding-left: 10px;
                    padding-right: 12px;
                    padding-bottom: 10px;
                  "
                >
                  <v-btn
                    dark
                    id="ButtonAnimationControls"
                    class="pause-name"
                    style="cursor: pointer"
                    @click.stop="restartVideo()"
                  >
                    <v-img
                      src="@/assets/images/player-pause-icon.svg"
                      width="19"
                      height="18"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                  <ControlSpacer />
                  <v-btn
                    v-if="PlayBtn"
                    dark
                    id="ButtonAnimationControls"
                    class="play-name mb-3"
                    style="cursor: pointer"
                    @click="playVideo(playing)"
                  >
                    <v-img
                      src="@/assets/images/player-play-icon.svg"
                      width="20"
                      height="19"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    id="ButtonAnimationControls"
                    class="play-name mb-3"
                    style="cursor: pointer"
                    @click="playVideo(playing)"
                  >
                    <v-icon style="font-size: 27px" color="white"
                      >mdi-pause</v-icon
                    >
                  </v-btn>
                  <ControlSpacer />
                  <v-btn
                    dark
                    id="ButtonAnimationControls"
                    class="fullscreen-name mb-3"
                    style="cursor: pointer"
                    @click.stop="FullScreen(fullScreen)"
                  >
                    <v-img
                      src="@/assets/images/player-fullscreen-icon.svg"
                      width="32"
                      height="24"
                      class="mx-auto"
                    ></v-img>
                  </v-btn>
                </ControlGroup>
              </div>
            </Controls>
          </Ui>
        </Player>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  Player,
  Video,
  Ui,
  Scrim,
  ControlGroup,
  //ScrubberControl,
  Controls,
  ControlSpacer,
  CurrentTime,
  EndTime,
} from "@vime/vue";
export default {
  components: {
    Player,
    Video,
    Ui,
    Scrim,
    ControlGroup,
    //ScrubberControl,
    Controls,
    ControlSpacer,
    CurrentTime,
    EndTime,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    videoName: String,
    //Addfav: Boolean,
    //request_flag: Boolean,
    videoDescription: String,
    videoFile: String,
    contentid: Number,
    courseID: Number,
    // lastWatchedTime: String,
  },
  data() {
    return {
      loadVideo: true,
      buffering: false,
      PlayBtn: true,
      dragging: false,
      finishVideo: false,
      fullScreen: false,
      content_id: 0,
      duration: -1,
      progress_time: 0,
      centisecond: 0,
      seeking: false,
      actualPlayTime: 0, // work as seconds
      playing: false,
      restart: false, //use for restart the video
      currentTime: 0,
      playback_started: false,
      isFullscreenBefore: false,
      isFullscreen: false,
      complete_dialog: false,
      dialogWidth: "800px",
      isFavourite: false,
      Videolink: "",
      //videoPlayerDialog: false,
      mPlay: true,
    };
  },
  computed: {
    progressPercentage() {
      return (this.currentTime / this.duration) * 100 + "%";
    },
    player() {
      return this.$refs.player;
    },
    videoPlayerDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDialogWidth);
  },
  methods: {
    onVideoBuffering(buffering) {
      this.loadVideo = buffering;
    },
     onReadytoPlay() {
     const player = this.$refs.player;
      if (player && player.pause) {
        setTimeout(() => {
          this.currentTime = 0;
          player.play().catch((error) => {
            console.error("Failed to play video:", error);
          });
        }, 500);
      }
    },
    getFavorite(v) {
      this.isFavourite = v;
    },
    seekVideo(event) {
      const progressBar = event.currentTarget;
      const boundingRect = progressBar.getBoundingClientRect();
      const clickPosition = event.clientX - boundingRect.left;
      const progressRatio = clickPosition / boundingRect.width;
      const seekTime = this.duration * progressRatio;
      this.currentTime = seekTime;
    },
    formatTime(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const remainingSeconds = (t_seconds % 60).toFixed(1);
      return `${minutes}:${remainingSeconds.padStart(4, "0")}`;
    },
    formatTimeForMinuteData(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const seconds = Math.floor(t_seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    cancelExitPlayer() {
      this.exit_dialog = false;
      if (this.isFullscreenBefore === true) {
        this.$refs.player.enterFullscreen();
      }
      this.$refs.player.play();
    },
    showExitVideoPlayerDialog() {
      this.videoPlayerDialog = false;
      if (this.isFullscreen === true) {
        this.$refs.player.exitFullscreen();
      }
      this.isFullscreenBefore = this.isFullscreen;
      if (this.currentTime == this.duration) {
        this.complete_dialog = false; // already finish the video
      } else {
        this.exit_dialog = true; // not finish the video
      }

      this.$refs.player.pause(); // if the video is playing, stop to show the dialog
    },
    showExitVideoPlayerDialogs() {
      if (this.currentTime == this.duration) {
        this.complete_dialog = false; // already finish the video
      } else {
        this.exit_dialog = true; // not finish the video
      }
    },
    onDurationChange(duration) {
      // get total time of video in seconds
      this.duration = duration;
    },
    onPlaybackEnded() {
      if (this.currentTime != 0) {
        this.videoPlayerDialog = true;
      } else {
        this.videoPlayerDialog = false;
      }
    },
    onStartNewPlayback() {
      this.complete_dialog = false;
    },
    playVideo(play) {
      let self = this;
      if(play === false){
        self.playing = true;
      }else{
        self.playing = false;
      }
      if (self.playing) {
        self.$refs.player.play();
      } else {
        self.$refs.player.pause();
      }
      self.showExitVideoPlayerDialogs();
    },
    FullScreen(full) {
      let self = this;
      if(full === false){
        self.fullScreen = true;
      }else{
        self.fullScreen = false;
      }
      if (self.fullScreen) {
        self.$refs.player.enterFullscreen();
      } else {
        self.$refs.player.exitFullscreen();
      }
    },
    restartVideo() {
      this.currentTime = 0;
      this.restart = true;
    },
    onPlayingChange(play) {
      this.playing = play;
      this.PlayBtn = !play;
      if (this.playing) {
        this.loadVideo = false;
      }
    },
    onSeekedVideo(v) {
      this.seeking = v;
    },
    onTimeUpdate(time) {
      this.currentTime = time;
      this.centisecond += 1; //count to get pure seconds

      if (this.restart === true) {
        this.currentTime = 0;
        this.centisecond = 0;
        if (this.playing === false) {
          this.$refs.player.play();
        }
      }

      //get actual view time in seconds
      if (this.centisecond == 60) {
        this.actualPlayTime += this.seeking === false ? 1 : 0;
        this.centisecond = 0;
      }
      this.seeking = false;

      //after restart from 0s
      this.restart = false;
    },
    onFullscreenChange(active) {
      this.isFullscreen = active;
      this.fullScreen = active;
    },
    alreadyPlayableend() {
      if (this.duration == 0 && this.currentTime != this.duration) {
        this.videoPlayerDialog = true;
      } else {
        this.videoPlayerDialog = false;
      }
    },
    //old video player
    closeDialog() {
      this.videoPlayerDialog = false;
    },
    onCurrentSrcChange() {
      this.currentTime = 0;
      this.actualPlayTime = 0;
    },
    updateDialogWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "800px";
      }
    },
  },
};
</script>

<style scoped>
.responsive-dialog {
  width: 50% !important;
  height: 50% !important;
  max-width: 800px !important;
  max-height: 500px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.dialog-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.dialog-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Adjust the dialog container width based on screen size */
@media only screen and (max-width: 768px) {
  .dialog-container {
    max-width: 90%;
  }
}

/* Adjust the player width based on screen size */
@media only screen and (max-width: 600px) {
  .responsive-dialog .v-player {
    max-width: 100%;
  }
}
</style>

<style scoped>
.heart-icon-container {
  position: absolute;
  top: 20px;
  right: 24px;
  height: 30px;
  z-index: 1000 !important;
}
.back-icon-container {
  position: absolute;
  top: 20px;
  left: 24px;
  z-index: 1000 !important;
}
.controls-padding {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
#ButtonAnimationControls {
  display: inline-block;
  text-align: center;
  color: transparent;
  border-radius: 4px !important;
  border: transparent !important;
  background: transparent;
  background: unset;
  padding-bottom: 12px;
  background-size: 100% 200%;
}
#ButtonAnimationHeart {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: #000000a3 !important;
  padding: 8px 7px;
}
#ButtonAnimationBack {
  height: 42px;
  display: inline-block;
  text-align: center;
  border-radius: 8px !important;
  background: #000000a3 !important;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;
}
::v-deep .pause-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 20px;
  min-height: 25px;
  padding-right: 11px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .play-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 20px;
  min-height: 25px;
  padding-right: 10px;
  padding-left: 11px;
  box-shadow: unset !important;
}
::v-deep .fullscreen-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 25px;
  min-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  box-shadow: unset !important;
}
</style>

<style scoped>
.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
}
.progress {
  height: 100%;
  background-color: #a6cc39;
  width: 0%;
  transition: width 0.1s ease-in-out;
}
.progress-bar__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.progress-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #a6cc39;
  transition: width 0.1s linear;
}
.progress-bar__handle {
  position: absolute;
  top: -5px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #a6cc39;
  transform: translateX(-50%);
  transition: left 0.1s linear;
}
</style>

<style>
.controls {
  display: flex;
  width: 93%;
  height: 90px;
  position: absolute;
  flex-wrap: wrap;
  pointer-events: auto;
  box-sizing: border-box;
  background: #000000a3 !important;
  padding-top: 12px !important;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 8px;
  opacity: 1;
  visibility: visible !important;
  bottom: 10px !important;
}
.player {
  line-height: 1;
}
::v-deep .controls.active {
  opacity: 1;
  visibility: visible;
  justify-content: center !important;
}
.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
