<template>
  <div>
    <v-dialog v-model="dialog" width="1200" persistent>
      <v-card class="rounded-lg" height="280">
        <template>
          <v-container fluid>
            <v-row
              v-show="startLoadingAudio"
              style="display: flex; justify-content: center"
            >
              <v-col
                class="pt-4 mt-8"
                cols="12"
                style="display: flex; justify-content: center"
              >
                <span>{{ "Loading audio file...." }}</span>
              </v-col>
              <v-col
                class="pt-0 pl-10 pr-10"
                cols="12"
                style="display: flex; justify-content: center"
              >
                <v-progress-linear
                  class="ml-3 mr-3"
                  v-model="power"
                  color="#4FB14E"
                  height="10"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row class="d-flex mt-4">
              <v-col
                cols="12"
                lg="8"
                md="8"
                sm="12"
                class="border pl-7"
                v-show="showMp3Player"
              >
                <v-row>
                  <v-col cols="12" md="10" lg="10" sm="12">
                    <p class="mb-0" style="font-size: 28px; color: #424242">
                      {{ cutText() }}
                    </p>

                    <!-- <p
                      class="mb-0"
                      style="font-size: 18px; color: #424242; opacity: 0.6"
                    >
                      {{ this.audioDescription }}
                    </p> -->
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    lg="2"
                    sm="12"
                    style="text-align: right"
                  >
                    <v-icon
                      v-if="!showPlay"
                      @click="pauseAudio"
                      class="mr-2"
                      style="font-size: 60px"
                      color="#A6CC39"
                      >mdi-pause-circle</v-icon
                    >

                    <v-icon
                      v-if="showPlay"
                      @click="playAudio"
                      class="mr-2"
                      style="font-size: 60px"
                      color="#A6CC39"
                      >mdi-play-circle</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div id="waveform"></div>
                    <v-row>
                      <v-col>
                        <span>{{ currentAudioTime }}</span>
                      </v-col>
                      <v-col
                        style="
                          align-items: flex-end;
                          display: flex;
                          justify-content: end;
                        "
                      >
                        <span class="mr-4">{{ audioDuration }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-show="showMp3Player"
                class="border"
                cols="12"
                lg="4"
                md="4"
                sm="12"
                style="
                  display: flex;
                  padding-left: 10px;
                  padding-right: 25px;
                  padding-bottom: 10px;
                  padding-top: 0px;
                  justify-content: center;
                "
              >
                <v-img :src="audioImg" class="audioPhoto"></v-img>
                <v-btn
                  class="mb-5"
                  icon
                  @click="closeSoundDialog"
                  color="#424242"
                  style="
                    border-radius: 8px !important;
                    top: -20px !important;
                    left: 4px !important;
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WaveSurfer1 from "wavesurfer.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    audioName: String,
    audioDescription: String,
    audioImg: String,
    audioFile: String,
    contentid: Number,
    currentminutes: String,
  },
  data() {
    return {
      showMp3Player: false,
      power: 0,
      startLoadingAudio: false,
      currentAudioTime: "0:00",
      audioDuration: "0:00",
      showPlay: false,
      wavesurfer: null,
      wavesurferOptions: {
        container: "#waveform",
        waveColor: "#eee", //#D2EDD4
        progressColor: "#A6CC39", //#46B54D
        cursorColor: "#424242",
        barHeight: 1,
        barRadius: 3,
        barWidth: 3,
        height: 95,
        responsive: true,
        origin: "*",
        backend: "MediaElement",
      },

      // AddReport
      progress_time: 0,
      actualViewTime: 0,
      intervalId: 0,
      complete_dialog: false,
      confirmDialog: false,
      Addfav: false,
      maxlength: 30,
    };
  },
  watch: {
    audioFile(val) {
      setTimeout(() => {
        if (val) {
          this.PlayMP3(val);
        }
      }, 100);
    },
  },

  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    cutText() {
      if (this.audioName.length <= this.maxlength) {
        return this.audioName;
      } else {
        return this.audioName.substring(0, this.maxlength) + "...";
      }
    },
    async closeSoundDialog() {
      this.wavesurfer.destroy();
      this.startLoadingAudio = false;
      this.currentAudioTime = "0:00";
      this.audioDuration = "0:00";
      this.showPlay = false;
      this.wavesurfer = null;
      this.$emit("show_sound");
    },
    PlayMP3(item) {
      let self = this;
      self.showPlay = true;

      //load audio waveform
      self.wavesurfer = WaveSurfer1.create(self.wavesurferOptions);
      self.wavesurfer.load(item);

      self.wavesurfer.on("ready", () => {
        const desiredTime =
          self.currentminutes != null ? self.currentminutes : 0; // Desired time in seconds
        const position = desiredTime / self.wavesurfer.getDuration();
        self.wavesurfer.seekTo(position);
        self.wavesurfer.play();

        self.audioDuration = self.formatTimeNew(self.wavesurfer.getDuration());
      });

      self.wavesurfer.on("loading", (percent) => {
        self.power = percent;
        self.startLoadingAudio = percent === 100 ? false : true;

        if (percent === 100) {
          self.playAudio();
          self.startLoadingAudio = false;
          self.showMp3Player = true;
        }
      });
      self.wavesurfer.on("audioprocess", () => {
        self.currentAudioTime = self.formatTimeNew(
          self.wavesurfer.getCurrentTime()
        );
      });

      this.wavesurfer.on("play", () => {
        // Start view time tracking timer
        self.showPlay = false;
        this.intervalId = setInterval(() => {
          this.actualViewTime += 1;

          if (this.actualViewTime >= this.audioDuration) {
            clearInterval(self.intervalId);
          }
        }, 1000); // update the view time every 1 second
      });

      this.wavesurfer.on("pause", () => {
        // Stop view time tracking timer
        clearInterval(self.intervalId);
      });

      this.wavesurfer.on("finish", () => {
        self.closeSoundDialog();
      });
    },

    formatTimeNew(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    formatTime(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const remainingSeconds = (t_seconds % 60).toFixed(1);
      return `${minutes}:${remainingSeconds.padStart(4, "0")}`;
    },
    convertToSeconds(timeString) {
      const [minutes, seconds] = timeString.split(":").map(parseFloat);
      return minutes * 60 + seconds;
    },
    playAudio() {
      this.wavesurfer.play();
      this.showPlay = false;
    },

    pauseAudio() {
      this.wavesurfer.pause();
      this.showPlay = true;
    },
    cancelExitPlayer() {
      this.dialog = true;
      this.showPlay = false;
      this.$emit("show_sound");
      this.wavesurfer.play();
    },
  },
};
</script>

<style scoped>
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.lessonimg {
  width: 192px !important;
  height: 130px !important;
  border-radius: 12px;
}
.lessonTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.lessonDes {
  font-size: 14px;
  opacity: 0.6;
}
.lessonIcon {
  margin-left: 20px;
  color: #4fb14e;
  font-size: 40px;
  top: 10px;
  position: absolute;
}
::v-deep .audioPhoto {
  object-fit: cover;
  /* width: 280px; */
  border-radius: 16px;
}
.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
