<template>
  <div>
    <Breadcrumbs page_title="sound" :items="breadcrumbs" />
    <v-container fluid>
      <v-card>
        <v-row justify="space-between" class="ma-1 pa-2">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            lg="6"
            md="12"
            sm="12"
            class="storecol"
            v-for="(item, i) in articleItems"
            :key="i"
          >
            <v-list>
              <v-list-item>
                <v-col cols="3">
                  <v-img
                    style="
                      height: 120px;
                      width: 300px;
                      border-radius: 10px !important;
                    "
                    :src="item.pictureBackground"
                  ></v-img>
                </v-col>
                <v-col cols="4" lg="4" class="content">
                  <v-list-item-content>
                    <v-list-item-title
                      class="title"
                      v-text="item.lessonName"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="subtitle"
                      v-text="item.wordDescription"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      dark
                      depressed
                      class="store_btn text-capitalize white--text mr-3"
                      color="#A6CC39"
                      style="border-radius: 8px !important"
                      @click="Actions(item)"
                    >
                      <v-icon text-white class="mr-1" :small="true"
                        >mdi-plus-box-outline</v-icon
                      >
                      <span style="font-size: 14px; color: #ffffff">{{
                        $t("addlibrary")
                      }}</span>
                    </v-btn>
                    <v-btn
                      dark
                      depressed
                      class="store_btn text-capitalize white--text"
                      color="#A6CC39"
                      style="border-radius: 8px !important"
                      @click="ViewSound(item)"
                    >
                      <v-icon text-white class="mr-1" :small="true"
                        >mdi-eye-outline</v-icon
                      >
                      <span style="font-size: 14px; color: #ffffff">{{
                        $t("view")
                      }}</span>
                    </v-btn>
                  </div>

                  <v-list-item-subtitle class="numberofview mt-5">
                    {{ item.countView }}
                    {{ item.countView > 1 ? $t("views") : $t("view") }}
                  </v-list-item-subtitle>
                </v-col>
              </v-list-item>
            </v-list>
            <div class="test">
              <div class="border1"></div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <!-- Add Library Dialog -->
    <v-dialog
      v-model="addLibraryDialog"
      width="700px"
      height="800px"
      persistent
    >
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("Add Library") }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="close()"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-row justify="space-between" class="ma-1 pa-2">
          <v-col cols="12" lg="7">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <span class="pl-6" style="color: #ff6060; font-size: 18px"
          >*{{ $t("OnlyoneCat") }}</span
        >
        <v-row no-gutters class="d-flex px-6">
          <v-col cols="12">
            <v-radio-group class="mt-3" hide-details>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="filteredData"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                class="user-table"
                v-model="selected"
                item-key="id"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <v-radio
                        @change="selectItem(item)"
                        :value="item"
                        :input-value="selectedItem === item"
                        name="selectedItem"
                        color="#a6cc39"
                      >
                      </v-radio>
                    </td>

                    <td>{{ item.topicName }}</td>
                    <td color="primary" v-if="item.status == true">
                      <span
                        style="font-size: 16px; color: #a6cc39"
                        class="text-capitalize"
                        >{{ "Active" }}</span
                      >
                    </td>
                    <td v-else class="text-capitalize">
                      <span style="font-size: 16px; color: #d52a2a">{{
                        "Inactive"
                      }}</span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-col cols="auto" class="d-flex justify-left pl-6"
          ><v-pagination
            circle
            class="pagination"
            v-model="page"
            :length="pageCount"
          ></v-pagination
        ></v-col>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="close()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Add to library  -->
    <v-dialog
      v-model="Confirmdialog"
      width="600px"
      height="800px"
      class="rounded-lg"
    >
      <v-card>
        <div v-if="selectedItem">
          <v-card-title class="confirmtitle" style="justify-content: center"
            >{{ $t("addsoundcatergory") }} "{{ selectedItem.topicName }}"
            {{ $t("category") }}</v-card-title
          >
          <div class="d-flex justify-center align-center">
            <v-progress-circular
              v-if="actionloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
          </div>
          <v-card-actions class="d-flex justify-center pb-5">
            <v-btn
              text
              class="text-capitalize btnfont-style mr-8"
              style="
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                color: #424242;
              "
              @click="closeconfirm()"
            >
              {{ $t("no") }}
            </v-btn>
            <v-btn
              class="text-capitalize btnfont-style btn_hover_effect ml-8"
              style="
                color: #ffff;
                border: 1px solid #a6cc39;
                border-radius: 8px;
              "
              color="#A6CC39"
              @click="addsound()"
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- Go to Sound Article -->
    <v-dialog v-model="goSound" width="600px" height="800px" class="rounded-lg">
      <v-card>
        <v-card-title class="confirmtitle pb-0" style="justify-content: center">
          {{ $t("addedsound") }}
        </v-card-title>
        <v-card-title class="confirmtitle pt-1" style="justify-content: center">
          {{ $t("gocontent") }}
        </v-card-title>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            text
            class="text-capitalize btnfont-style mr-8"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="$router.push('/home/store')"
          >
            {{ $t("no") }}
          </v-btn>
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect ml-8"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="
              $router.push({
                path:
                  '/home/library/' +
                  selectedItem.topicName.split('?').join('') +
                  '/Sound',
                query: { topic_name: selectedItem.topicName },
              })
            "
          >
            {{ $t("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sound view -->
    <v-dialog
      v-model="SoundViewdialog"
      width="350"
      @click:outside="closeSoundDialog"
    >
      <v-card
        style="
          border-radius: 16px;
          background-color: #f0f0f0;
          box-shadow: unset;
        "
        height="375"
      >
        <v-card-text
          justify="center"
          align="center"
          style="
            height: 100%;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 5px;
          "
        >
          <p class="text-center mb-2" style="font-size: 18px; color: #424242">
            "{{ soundList.lessonName }}"
          </p>
          <v-img :src="soundList.pictureBackground" class="soundimg"></v-img>
          <div
            style="height: 135px; overflow-x: hidden; overflow-y: auto"
            class="overflow-y-auto mt-4"
          >
            <v-row
              v-for="(item1, i) in soundList.partfileList"
              :key="i"
              style="cursor: pointer"
            >
              <v-col
                cols="12"
                sm="6"
                xs="6"
                md="3"
                lg="2"
                class="pl-5 pr-3 pb-2"
                style="text-align: center"
              >
                <v-img src="@/assets/images/soundicon.svg" class="musicimg">
                </v-img>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                xs="6"
                md="9"
                lg="9"
                class="pt-1 pb-1 mt-3"
                style="text-align: left"
              >
                <div class="contentTextStyle" @click="PlayMP3(item1)">
                  <span style="font-size: 16px; color: #424242">{{
                    item1.songName
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <soundPlay
      ref="soundPlay"
      :show="showSound"
      @close="showSound = false"
      @show_sound="show_sound"
      :audioName="audioName"
      :audioDescription="audioDescription"
      :dialog="dialog"
      :audioFile="audioFile"
      :audioImg="audioImg"
      :contentid="contentId"
      :currentminutes="currentminutes"
    ></soundPlay>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import soundPlay from "./soundview.vue";

export default {
  components: {
    soundPlay,
  },
  data() {
    return {
      permissionDialog: false,
      permissionMessage: "",
      search: "",
      articleItems: [],
      addLibraryDialog: false,
      articleLibray: [],
      page: 1,
      pageCount: 0,
      loading: true,
      selectedItem: null,
      items: [],
      selected: [],
      Confirmdialog: false,
      actionloading: false,
      goSound: false,
      SoundViewdialog: false,
      soundList: [],
      showSound: false,
      audioImg: "",
      activeItem: null,
      contentId: 0,
      audioFile: "",
      dialog: false,
      audioDescription: "",
      audioName: "",
      currentminutes: "0",
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          sidebar_tilte: "home",
        },
        {
          text: "store",
          href: "/home/store",
        },
        {
          text: "sound",
          href: "/home/sound",
        },
      ];
    },
    headers() {
      return [
        {
          value: "id",
          class: "tableColor",
          align: "left",
          width: "60px",
        },
        {
          text: this.$t("category"),
          align: "left",
          value: "topicName",
          class: "tableColor",
          width: "160px",
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          class: "tableColor",
          width: "130px",
        },
      ];
    },
    filteredData() {
      if (this.search) {
        const searchRegex = new RegExp(this.search, "i");
        return this.items.filter((item) => {
          for (const key in item) {
            if (searchRegex.test(item[key])) {
              return true;
            }
          }
          return false;
        });
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    this.getArticle();
    this.getLibrary();
  },
  methods: {
    async Actions(data) {
      let checkPermission = this.check_Permissions("store");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to add Sound from Library.";
      } else {
        this.addLibraryDialog = true;
        this.articleLibray = data;
      }
    },
    async addsound() {
      let self = this;
      self.actionloading = true;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;
      let request = {
        id: self.articleLibray.id,
        topicID: self.selectedItem.id,
        createBy: memberid,
        companyID: localStorage.getItem("companyID"),
      };
      await self.$axios
        .post(`${self.web_url}Contents/AddSoundFromStoreToLibrary`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.actionloading = false;
            self.goSound = true;
            self.closeconfirm();
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async getArticle() {
      let self = this;
      self.loading = true;
      axios
        .get(`${self.web_url}Video/GetVideoContentFromProductAdmin`)
        .then(function (response) {
          self.articleItems = response.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
            createDate: moment(v.createDate).local().format("DD/MM/YYYY"),
          }));
          self.loading = false;
        })
        .catch(function (res) {
          alert(res);
        });
    },
    close() {
      this.addLibraryDialog = false;
    },
    closeconfirm() {
      this.Confirmdialog = false;
    },
    async getLibrary() {
      let self = this;
      self.loading = true;
      axios
        .get(
          `${self.web_url}Topic/GetTopics?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          self.items = response.data.data;
          self.loading = false;
        })
        .catch(function (res) {
          alert(res);
        });
    },
    selectItem(itemId) {
      this.selectedItem = itemId;
    },
    submit() {
      let self = this;
      if (this.selectedItem === null) {
        alert("Please select one category!");
      } else {
        self.Confirmdialog = true;
      }
    },
    ViewSound(item) {
      let self = this;
      self.SoundViewdialog = true;
      self.soundList = item;
    },
    closeSoundDialog() {
      this.SoundViewdialog = false;
      this.getArticle();
    },
    show_sound() {
      let self = this;
      self.showSound = false;
      self.audioImg = "";
      self.audioName = "";
      self.audioDescription = "";
      self.contentId = 0;
      self.audioFile = "";
      self.currentminutes = "0";
    },
    PlayMP3(item) {
      let self = this;

      self.showSound = true;
      self.audioImg = self.soundList.pictureBackground;
      self.audioName = item.songName;
      self.audioDescription = self.soundList.wordDescription;
      self.contentId = item.videoLessonID;
      self.audioFile = item.file;
      self.currentminutes = "0";
      const request = {
        id: item.videoLessonID,
        separateTopic: "Sound",
      };
      axios.post(`${self.web_url}Contents/UpdateViews`, request);
    },
  },
};
</script>

<style>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .storecol {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .content {
    width: 45%;
    max-width: 45%;
    flex-basis: 45%;
  }
}
</style>

<style scoped>
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.cardTitle {
  position: relative;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  top: 40px;
}
.icons {
  position: absolute;
  bottom: -13px;
  right: -10px;
}
.v-card {
  border-radius: 20px;
}
.title {
  margin-bottom: 20px;
  font-size: 20px !important;
  color: #424242;
}
.store_btn {
  top: -20px !important;
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.store_btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.store_btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.store_btn:hover {
  transition: 0.25s;
}
.store_btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.numberofview {
  margin-right: 10px !important;
  display: flex !important;
  justify-content: end;
  color: #424242;
  font-size: 18px !important;
}
</style>

<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .faq-table tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
  word-break: break-all;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
.v-text-field__details {
  display: none !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:last-child {
  border-radius: 0 8px 8px 0;
}
.confirmtitle {
  color: #363636;
  font-size: 20px !important;
}
.soundimg {
  height: 180px;
  object-fit: cover;
  width: 350px;
  border-radius: 16px;
}
.musicimg {
  margin: auto !important;
  width: 29px;
}
::v-deep ::-webkit-scrollbar {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep ::-webkit-scrollbar-track {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep ::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 1px #e6e6e6;
}
</style>
